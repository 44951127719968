import Partials from 'components/partials/Partials';
import type { BasisPageData } from 'types/cms';
import { getKey } from 'utils/idGenerator';
import styles from './article-index.module.scss';

const ArticleIndex = ({ context }: { context: BasisPageData }) => (
  <article className={styles.article}>
    {context.areas.main?.map((widget) => <Partials key={getKey(widget.id, widget)} context={widget} />)}
  </article>
);

export default ArticleIndex;
